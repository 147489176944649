import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IRegionListRegion} from "../../search/actions/fetch_search_regions_action";

interface IGetRegionDetailListPayload {
    regionId: number;
}

export const getRegionDetailApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getRegionDetail: build.query<IRegionListRegion, IGetRegionDetailListPayload>({
            query: ({regionId}) => ({
                url: apiV2Link.region.detail(Scenario.REGION_DETAIL, {regionId}),
                method: "GET"
            })
        })
    })
});

export const {useGetRegionDetailQuery} = getRegionDetailApi;
