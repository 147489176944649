import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IModularHouseGalleryTab} from "../types/IModularHouseGalleryTab";

interface IModularHouseOfferState {
    isOpened: boolean;
    offerId: number | null;
    selectedTab: IModularHouseGalleryTab;
}

const defaultTab: IModularHouseGalleryTab = "gallery";

const initialState: IModularHouseOfferState = {
    isOpened: false,
    offerId: null,
    selectedTab: defaultTab
};

export const modularHouseOfferGalleryModalSlice = createSlice({
    name: "modularHouseOfferGalleryModal",
    initialState,
    reducers: {
        openModularHouseOfferGalleryModal: (
            state,
            action: PayloadAction<{
                offerId: number;
                selectedTab?: IModularHouseGalleryTab;
            }>
        ) => {
            state.isOpened = true;
            state.offerId = action.payload.offerId;
            state.selectedTab = action.payload.selectedTab || defaultTab;
        },
        closeModularHouseOfferGalleryModal: (state) => {
            state.isOpened = false;
            state.offerId = null;
            state.selectedTab = defaultTab;
        },
        setSelectedTab: (state, action: PayloadAction<IModularHouseGalleryTab>) => {
            state.selectedTab = action.payload;
        }
    }
});

export const {openModularHouseOfferGalleryModal, closeModularHouseOfferGalleryModal, setSelectedTab} = modularHouseOfferGalleryModalSlice.actions;
