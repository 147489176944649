import {IModularHousesPublicOffer, mpPublicApiLinks} from "@pg-mono/rp-api-routes";

import {mpApi} from "../../app/api/mp_api";

interface IGetModularHouseOfferPayload {
    offerId: number;
}

export const getModularHousesOfferDetailApi = mpApi.injectEndpoints({
    endpoints: (builder) => ({
        getModularHousesOfferDetail: builder.query<IModularHousesPublicOffer, IGetModularHouseOfferPayload>({
            query: ({offerId}) => ({
                url: mpPublicApiLinks.marketplace.modularHouses.offer.detail({offerId}),
                method: "GET"
            }),
            providesTags: (result) => {
                if (result) {
                    return [{type: "ModularHouseOffer", id: result.id}, "ModularHouseOffer"];
                }

                return ["ModularHouseOffer"];
            }
        })
    })
});

export const {useGetModularHousesOfferDetailQuery} = getModularHousesOfferDetailApi;
