import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IRankingCity} from "../types/IRankingCity";

export const getRankingCities = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getRankingCities: build.query<IPaginatedResponse<IRankingCity>, void>({
            query: () => apiV2Link.ratings.cities.list(Scenario.RATINGS_CITIES_LIST)
        })
    })
});

export const {useGetRankingCitiesQuery} = getRankingCities;
