import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {ISearchedRegion} from "../../ranking/types/ISearchedRegion";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IPaginatedRequest} from "../../types/IPaginatedRequest";

interface IGetRegionDetailBySlugPayload extends Omit<IPaginatedRequest, "page"> {
    slug: string;
}

interface IGetRegionDetailBySlugResponse extends IPaginatedResponse<ISearchedRegion> {}

const regionListApiLink = apiV2ListLink.region.list(Scenario.SEARCH_REGION_DETAIL);

export const getRegionDetailListBySlug = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getRegionDetailListBySlug: build.query<IGetRegionDetailBySlugResponse, IGetRegionDetailBySlugPayload>({
            query: (params) => ({
                url: regionListApiLink,
                page: 1,
                params
            })
        })
    })
});

export const {useGetRegionDetailListBySlugQuery} = getRegionDetailListBySlug;
