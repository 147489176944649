import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IModularHouseOfferState {
    isOpened: boolean;
    offerId: number | null;
}

const initialState: IModularHouseOfferState = {
    isOpened: false,
    offerId: null
};

export const modularHouseOfferLeadModalSlice = createSlice({
    name: "modularHouseOfferLeadModal",
    initialState,
    reducers: {
        openModularHouseOfferLeadModal: (
            state,
            action: PayloadAction<{
                offerId: number;
            }>
        ) => {
            state.offerId = action.payload.offerId;
            state.isOpened = true;
        },
        closeModularHouseOfferLeadModal: (state) => {
            state.isOpened = false;
            state.offerId = null;
        }
    }
});

export const {openModularHouseOfferLeadModal, closeModularHouseOfferLeadModal} = modularHouseOfferLeadModalSlice.actions;
